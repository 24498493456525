import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import qna from 'assets/images/ic_question.svg';
import { useEffect, useState } from 'react';
import {
  Outlet,
  useNavigate,
  useOutlet,
  useSearchParams
} from 'react-router-dom';

// import './styles.scss';
import HotMomentIcon from 'components/IconSVG/HotMomentIcon';
import { setLoading } from 'components/Loading/loadingSlice';
import { useDispatch } from 'react-redux';
import PlaceService from 'services/place.service';
import { postInitialState } from 'types/entities/post';
import { PostState } from 'types/post.interface';
import './styles.scss';
import { calculateTimeAgo } from 'utils/formartTime';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import InfiniteScroll from 'react-infinite-scroll-component';
type ParamsType = {
  longtitude: string | null;
  latitude: string | null;
  
  page: number;
  limit: number;
};

const Post = () => {
  const outlet = useOutlet();
  return outlet ? <Outlet /> : <PostPage />;
};

const PostPage = () => {
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState<ParamsType>({
    longtitude: searchParams.get('lng'),
    latitude: searchParams.get('lat'),
    page: 1,
    limit: 10
  });
  const dispatch = useDispatch();
  const [dataPost, setDataPost] = useState<PostState>(postInitialState);
  const [location, setLocation] = useState<string>('');
  const [pagePost, setPagePost] = useState<number>(1);
  const navigate = useNavigate();
  const handleToFeedDetail = (id: number) => {
    navigate({
      pathname: `/posts/${id}`
    });
  };
  useEffect(() => {
    handleGetPostList();
    getLocationPost();
  }, [pagePost]);

  const getLocationPost = async () => {
    const res = await PlaceService.getLocationPost({
      longtitude: params.longtitude,
      latitude: params.latitude
    });
    setLocation(res?.data?.name);
  };
  const handleGetPostList = async () => {
    dispatch(setLoading(true));
    const res: any = await PlaceService.getPostDetail({
      ...params,
      page: pagePost
    });
    setDataPost((prevData) => {
      return {
        ...prevData,
        meta: res.meta,
        data: {
          posts: [...prevData?.data?.posts, ...res?.data?.posts]
        }
      };
    });
    dispatch(setLoading(false));
  };
const handleNextPagePost = () => {
  if (pagePost < dataPost.meta.pagination.total_pages) {
    setPagePost((pre) => pre + 1);
  }
};
  return (
    <>
      <Box className="place-content-post">
        <Box className="navigate">
          <IconButton className="button-back" onClick={() => navigate(-1)}>
            <ChevronLeftIcon fontSize="medium" />
          </IconButton>
          <Box className="location">
            <Box
              className="location-icon"
              display={dataPost.data.posts.length ? '' : 'none'}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="14"
                viewBox="0 0 11 14"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.51458 2.66645C4.03822 2.66338 2.82904 3.83441 2.82403 5.27183C2.81923 6.72604 4.01526 7.91593 5.49058 7.92453C6.96068 7.93293 8.18029 6.74141 8.18134 5.29519C8.18238 3.85019 6.98447 2.66953 5.51458 2.66645ZM10.9466 4.61285C11.1178 5.73225 10.8602 6.77071 10.4189 7.78294C9.73075 9.36174 8.74742 10.7561 7.61338 12.0534C7.01495 12.7378 6.36809 13.3748 5.65339 13.9438C5.56217 14.0166 5.50331 14.0205 5.41105 13.9449C3.53894 12.4054 1.93504 10.6485 0.83795 8.48598C0.331776 7.48808 -0.0117964 6.44204 0.000310014 5.31096C0.0268189 2.86275 1.81753 0.686039 4.24695 0.142015C4.66045 0.0496024 5.07875 -0.00100927 5.5031 1.52556e-05C8.22684 -0.00326323 10.5427 1.96998 10.9466 4.61285Z"
                  fill="black"
                />
              </svg>
            </Box>
            <Box className="location-text">{location}</Box>
          </Box>
          <Box sx={{
            opacity: 0,
          }}>
            <ChevronLeftIcon fontSize="medium" />
          </Box>
        </Box>

        <>
          <Box className="question-header">
            <Box className="left-question">질문</Box>
            <Box className="right-question">
              <Box className="icon" component={'img'} src={qna} />
              <Box className="question-total">{`0개의 질문`}</Box>
            </Box>
          </Box>

          <Divider className="divider" />
          <Box className="moment-header">
            <Box className="moment-header-left">순간</Box>
            <Box className="moment-header-right">
              <Box className="moment-header-right-icon">
                <HotMomentIcon />
              </Box>
              <Box className="moment-header-right-text">{`${dataPost.meta.pagination.total}개의 순간`}</Box>
            </Box>
          </Box>

          <Box className="moment" id={'posts'}>
            <InfiniteScroll
              scrollableTarget="posts"
              hasMore={true}
              loader={''}
              dataLength={dataPost?.data?.posts?.length}
              next={handleNextPagePost}
            >
              <Box className="moment-content">
                {dataPost?.data?.posts.map((item) => (
                  <Box className="moment-content-img" key={item.id}>
                    <div className="moment-content-img-time">{`${calculateTimeAgo(
                      item.createdAtImage
                    )}`}</div>
                    <img
                      onClick={() => handleToFeedDetail(item.id)}
                      src={item.imageUrl}
                      alt=""
                      className="moment-content-img-src"
                    />
                  </Box>
                ))}
              </Box>
            </InfiniteScroll>
          </Box>
        </>
      </Box>
    </>
  );
};

export default Post;
