const ShareIcon = () => {
    return (  
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="52"
                height="36"
                viewBox="0 0 52 36"
                fill="none"
              >
                <path
                  d="M20.8667 13.3333H17.7334C16.6288 13.3333 15.7334 14.2287 15.7334 15.3333V28.1333C15.7334 29.2378 16.6288 30.1333 17.7334 30.1333H34.2667C35.3713 30.1333 36.2667 29.2378 36.2667 28.1333V15.3333C36.2667 14.2287 35.3713 13.3333 34.2667 13.3333H30.563"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M26 6.80005L26 20.8"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M25.812 4.93335L22.2667 8.66671"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M26.188 4.93335L29.7333 8.66671"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
    );
}
 
export default ShareIcon
   