import AppleLogo from 'pages/Home/AppleLogo';
import Logo from 'pages/Home/Logo';
import './styles.scss';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
const Share = () => {
  useEffect(() => {
    let link = 'https://apps.apple.com/us/app/prezem-프레젬/id6446088313';
    if (
      window.navigator.userAgent.includes('iPad') ||
      window.navigator.userAgent.includes('iPhone')
    ) {
      window.location.replace(`${process.env.REACT_APP_DEEPLINK}`);
    } else {
      window.open(link);
    }
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100svh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div>
        <Box className="left-content">
          <Box className="typhography-line-1">
            <Box className="line-1">진짜</Box>
            <Box className="line-1-right">를 보여줄 시간,</Box>
          </Box>
          <Box className="typhography-line-2">프레젬 타임!</Box>
          <Box className="typhography-line-3">실시간 지도기반 핫플공유 SNS</Box>
          <Box className="logo">
            <Logo />
          </Box>
          <Box className="download">
            <Button
              className="button"
              target="_blank"
              href="https://apps.apple.com/kr/app/prezem-%ED%94%84%EB%A0%88%EC%A0%AC/id6446088313"
              variant="contained"
              startIcon={<AppleLogo />}
            >
              Download
            </Button>
          </Box>
        </Box>
        
      </div>
    </div>
  );
};

export default Share;
