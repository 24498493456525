import { styled, TextField } from '@mui/material';

export const SearchInput = styled(TextField)({
  width: '100%',
  height: '52px',
  '& label': {
    color: '#969696'
  },
  '& label.Mui-focused': {
    color: '#969696',
  },
  '& label.Mui-error': {
    color: '#d32f2f',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#F8F8F8',
    '& fieldset': {
      border: 'none',
    },
  },
});
