import { useNavigate } from 'react-router-dom';
import MapLayoutWithHeader from '../../../layouts/MapLayoutWithHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { FetchPopupStoreRankings, PopupStoreRanking } from '../../../services/popup-stores/fetchRankings';
import dayjs from 'dayjs';
import Ranking1 from 'assets/images/ranking1.svg';
import Ranking2 from 'assets/images/ranking2.svg';
import Ranking3 from 'assets/images/ranking3.svg';
import { ButtonBase } from '@mui/material';
import Box from '@mui/material/Box';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Button from '@mui/material/Button';
import Sugar from '../../../assets/images/ic_sugar.svg';

const useRakingPopupStores = () => {
  const navigate = useNavigate();
  const [popupStoreRankings, setPopupStoreRankings] = useState<PopupStoreRanking[]>([]);
  const rankingImages = [Ranking1, Ranking2, Ranking3];

  useEffect(() => {
    async function fetchPopupStoreRankings() {
      const popupStores = await FetchPopupStoreRankings();
      setPopupStoreRankings(popupStores);
    }

    fetchPopupStoreRankings();
  }, []);

  return {
    models: {
      popupStoreRankings,
      rankingImages,
    },
    operations: {
      navigate,
    },
  };
}

export default function RakingPopupStores() {
  const { models, operations } = useRakingPopupStores();

  return (
    <MapLayoutWithHeader title="인기 팝업스토어"
                         onClickBackButton={() => operations.navigate(-1)}>
      <Grid container
            alignItems="baseline"
            mb="16px">
        <Grid item>
          <Typography fontFamily="Apple SD Gothic Neo"
                      fontSize="18px"
                      fontWeight={700}
                      lineHeight="21.6px"
                      color="#000000">실시간 팝업 TOP 10 🔥</Typography>
        </Grid>
      </Grid>

      <Grid container>
        {models.popupStoreRankings.length > 0 && models.popupStoreRankings.map((popupStoreRanking: PopupStoreRanking, index) => {
          const { isPreReservation, isAssociated, viewCount, startDate, endDate } = popupStoreRanking;
          const ranking = index >= 3 ? null : models.rankingImages[index];
          const addressSplit = popupStoreRanking.address.split(' ');

          return (
            <Grid key={popupStoreRanking.id}
                  container
                  display="flex"
                  justifyContent="start">
              <Grid item
                    sx={{
                      width: '25px',
                      height: '30px',
                    }}>
                <ButtonBase onClick={() => operations.navigate(`/popup-stores/${popupStoreRanking.id}`)}>
                  {ranking ? (
                    <Box component="img"
                         src={ranking}
                    />) : (
                    <Box display="flex"
                         justifyContent="center"
                         alignItems="center"
                         sx={{
                           width: "25px",
                           height: "30px",
                         }}>
                      <Typography fontFamily="Apple SD Gothic Neo"
                                  fontSize="15px"
                                  fontWeight={700}
                                  lineHeight="21.6px"
                                  color="#000000">{index + 1}</Typography>
                    </Box>
                  )}
                </ButtonBase>
              </Grid>
              <Grid item
                    mb={1}
                    sx={{
                      marginLeft: '10px',
                      marginRight: '10px',
                      width: '140px',
                      height: '140px',
                    }}>
                <ButtonBase onClick={() => operations.navigate(`/popup-stores/${popupStoreRanking.id}`)}>
                  <Box component="img"
                       src={popupStoreRanking.thumbnailImages[0]}
                       borderRadius="8px"
                       width="140px"
                       height="140px" />
                </ButtonBase>
              </Grid>
              <Grid item
                    whiteSpace="nowrap"
                    sx={{
                      width: 'calc(100% - 185px)',
                    }}>
                <ButtonBase onClick={() => operations.navigate(`/popup-stores/${popupStoreRanking.id}`)}
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'start',
                            }}>
                  <Typography fontFamily="Apple SD Gothic Neo"
                              fontSize="14px"
                              fontWeight={600}
                              lineHeight="16.8px"
                              color="#000000"
                              overflow="hidden"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis">{popupStoreRanking.name}</Typography>
                </ButtonBase>
                <Typography fontFamily="Apple SD Gothic Neo"
                            fontSize="12px"
                            fontWeight={400}
                            lineHeight="14.4px"
                            mt={1}
                            color="#6F6F6F">
                  <LocationOnIcon fontSize="inherit" />{addressSplit[0]} {addressSplit[1]}
                </Typography>
                <Typography fontFamily="Apple SD Gothic Neo"
                            fontSize="12px"
                            fontWeight={500}
                            lineHeight="14.4px"
                            mt={1}
                            color="#6F6F6F">{dayjs(startDate).format('MM.DD(dd)')} ~ {dayjs(endDate).format('MM.DD(dd)')}</Typography>
                <Typography fontFamily="Apple SD Gothic Neo"
                            fontSize="12px"
                            fontWeight={700}
                            lineHeight="14.4px"
                            mt={1}
                            color="#000000"><b>🔥{viewCount.toLocaleString('en')}명</b>이 봤어요</Typography>
                <Box mt={1}>
                  {isAssociated && (
                    <Button size="small"
                            sx={{
                              backgroundColor: '#FFFFFF',
                              borderRadius: '18px',
                              border: '0.5px solid #969696',
                              padding: 0,
                              "&:hover": {
                                backgroundColor: '#FFFFFF',
                              },
                              marginRight: '5px',
                            }}>
                      <Box component="img"
                           src={Sugar} /> &nbsp;
                      <Typography fontFamily="Apple SD Gothic Neo"
                                  fontSize="9px"
                                  fontWeight={700}
                                  color="#000000"
                                  lineHeight="10.8px">
                        리워드</Typography>
                    </Button>
                  )}
                  {isPreReservation && (
                    <Button size="small"
                            sx={{
                              backgroundColor: '#FFFFFF',
                              borderRadius: '18px',
                              border: '.5px solid #969696',
                              padding: "6px 5px",
                              "&:hover": {
                                backgroundColor: '#FFFFFF',
                              },
                            }}>
                      <Typography fontFamily="Apple SD Gothic Neo"
                                  fontSize="9px"
                                  fontWeight={700}
                                  color="#000000"
                                  lineHeight="10.8px">🎟️ 예약가능</Typography>
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </MapLayoutWithHeader>
  );
}