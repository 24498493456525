import axiosInstance, { CoreResponse } from '../axiosInstance';
import { PopupStorePost } from '../presentation/PopupStorePost';

interface FetchPopupStorePostsResponse extends CoreResponse {
  posts: PopupStorePost[];
}

export const FetchPopupStorePosts = async function(id: number) {
  try {
    const { data } = await axiosInstance.get<FetchPopupStorePostsResponse>(`v2/popup-stores/${id}/posts`);

    return data.posts;
  } catch {
    return [];
  }
}
