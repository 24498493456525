import axiosInstance, { CoreResponse } from '../axiosInstance';
import { PopupStore } from '../presentation/PopupStore';

interface FetchPopupStoresResponse extends CoreResponse {
  popupStore: PopupStore;
}

export const FetchPopupStore = async function(id: number) {
  try {
    const { data } = await axiosInstance.get<FetchPopupStoresResponse>(`v2/popup-stores/${id}`);

    return data.popupStore;
  } catch {
    return null;
  }
}
