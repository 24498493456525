import React from 'react';

interface AppleLogoProps {
  fill?: string;
}

const AppleLogo = ({ fill = "black" }: AppleLogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8652 5C25.0919 6.51917 24.4694 8.00583 23.6485 9.05833C22.7694 10.1867 21.2552 11.06 19.7927 11.0142C19.526 9.56 20.2119 8.06417 21.0427 7.05583C21.9585 5.94417 23.5252 5.09167 24.8652 5ZM29.031 27.3892C29.7877 26.2392 30.0685 25.6592 30.656 24.3575C26.391 22.745 25.7052 16.7175 29.9269 14.4033C28.6377 12.7975 26.8294 11.8683 25.1227 11.8683C23.8906 11.8683 23.0467 12.1876 22.2797 12.4777C21.6409 12.7194 21.0555 12.9408 20.3435 12.9408C19.5743 12.9408 18.8928 12.6979 18.179 12.4435C17.3941 12.1637 16.5702 11.87 15.5477 11.87C13.6294 11.87 11.5894 13.0342 10.296 15.0258C8.47687 17.8267 8.7852 23.0967 11.7352 27.5867C12.7902 29.1933 14.1985 30.9992 16.0419 31.0158C16.8052 31.0232 17.3133 30.7968 17.8635 30.5517C18.4936 30.2711 19.1787 29.9658 20.3652 29.9592C21.559 29.9525 22.233 30.261 22.855 30.5457C23.3909 30.791 23.8881 31.0186 24.646 31.0117C26.4894 30.995 27.9769 28.995 29.031 27.3892Z"
        fill={fill}
      />
    </svg>
  );
};

export default AppleLogo;