import { styled } from '@mui/material';
import Box from '@mui/material/Box';

interface ToOpenPopupStoreProps {
  background: string;
  width?: string;
}

const ToOpenPopupStore = styled(Box)(({ background, width }: ToOpenPopupStoreProps) => ({
  width: width || '216px',
  minHeight: 0,
  aspectRatio: '1/1',
  borderRadius: '12px',
  backgroundColor: 'transparent',
  position: 'relative',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundImage: `url(${background})`,

  "&::before": {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    borderRadius: '12px',
    zIndex: 0,
    backgroundColor: '#000000',
    opacity: 0.2,
  },
}));

export default ToOpenPopupStore;