const LocationFeedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51458 2.66645C4.03822 2.66338 2.82904 3.83441 2.82403 5.27183C2.81923 6.72604 4.01526 7.91593 5.49058 7.92453C6.96068 7.93293 8.18029 6.74141 8.18134 5.29519C8.18238 3.85019 6.98447 2.66953 5.51458 2.66645ZM10.9466 4.61285C11.1178 5.73225 10.8602 6.77071 10.4189 7.78294C9.73075 9.36174 8.74742 10.7561 7.61338 12.0534C7.01495 12.7378 6.36809 13.3748 5.65339 13.9438C5.56217 14.0166 5.50331 14.0205 5.41105 13.9449C3.53894 12.4054 1.93504 10.6485 0.83795 8.48598C0.331776 7.48808 -0.0117964 6.44204 0.000310014 5.31096C0.0268189 2.86275 1.81753 0.686039 4.24695 0.142015C4.66045 0.0496024 5.07875 -0.00100927 5.5031 1.52556e-05C8.22684 -0.00326323 10.5427 1.96998 10.9466 4.61285Z"
        fill="white"
      />
    </svg>
  );
};

export default LocationFeedIcon;
