import { SvgIcon } from '@mui/material';
import React from 'react';
type LocationIconProps  = {
  className: string
}

const LocationIcon = ({className}: LocationIconProps) => {
  return (
    <SvgIcon className={className}>
      <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.42593 2.57143C4.27768 2.56893 3.33724 3.5234 3.33334 4.69498C3.32961 5.88026 4.25983 6.85009 5.40726 6.85711C6.55063 6.86395 7.49919 5.89278 7.5 4.71402C7.50081 3.53626 6.56913 2.57394 5.42593 2.57143ZM5.00282 1.30762e-05C7.47895 -0.00279706 9.58429 1.68855 9.95147 3.95387C10.1071 4.91336 9.87291 5.80347 9.47176 6.67109C8.84614 8.02435 7.9522 9.21954 6.92125 10.3315C6.37722 10.9181 5.78917 11.4641 5.13944 11.9519C5.05652 12.0142 5.00301 12.0176 4.91914 11.9527C3.21722 10.6332 1.75913 9.12733 0.761773 7.27369C0.301614 6.41836 -0.010724 5.52175 0.00028183 4.55225C0.0243808 2.45379 1.6523 0.588034 3.86087 0.121727C4.23677 0.0425164 4.61705 -0.000865091 5.00282 1.30762e-05Z"
          fill="#FF6151"
        />
        <ellipse
          cx="4.99984"
          cy="5.14293"
          rx="3.33333"
          ry="3.42857"
          fill="#FF6151"
        />
        <ellipse
          cx="5.00016"
          cy="5.14275"
          rx="1.66667"
          ry="1.71429"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default LocationIcon;