import CachedIcon from '@mui/icons-material/Cached';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import './styles.scss';
type RefreshButtonType ={
   onClick: React.MouseEventHandler<HTMLButtonElement | undefined>;

}

const RefreshButton = ({ onClick }: RefreshButtonType) => {
  const [rotate, setRotate] = useState(false);

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setRotate(true);

    // Your additional logic here
    if (onClick) {
      onClick(event);
    }

    // Reset rotation after animation duration (e.g., 1 second)
    setTimeout(() => {
      setRotate(false);
    }, 1000);
  };
  return (
    <IconButton onClick={handleButtonClick} className={`button-refresh`}>
      <CachedIcon className={`${rotate ? 'rotate-animation' : ''}`} />
    </IconButton>
  );
};

export default RefreshButton;
