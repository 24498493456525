import axiosInstance, { CoreResponse } from '../axiosInstance';

import { PopupStore } from '../presentation/PopupStore';

interface FetchPopupStoresResponse extends CoreResponse {
  popupStores: PopupStore[];
}

export const FetchPopupStores = async function(toOpen?: boolean) {
  try {
    const type = toOpen ? 'toOpen' : 'default';
    const { data } = await axiosInstance.get<FetchPopupStoresResponse>(`v2/popup-stores?type=${type}`);

    return data.popupStores;
  } catch {
    return [];
  }
}
