import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Appdownload from '../../assets/icons/app_download.svg';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ChevronRightWhite from '../../assets/icons/chevronright_white.svg';
import { Drawer } from '@mui/material';
import React from 'react';

interface DrawerRootProps {
  open: boolean;
  hideDrawer: () => void;
}

export default function DrawerRoot({ open, hideDrawer }: DrawerRootProps) {
  return (
    <Drawer anchor="bottom"
            open={open}
            PaperProps={{ style: { position: 'absolute' } }}
            BackdropProps={{ style: { position: 'absolute' } }}
            ModalProps={{
              container: document.getElementById('drawer-container'),
              style: { position: 'absolute' }
            }}
            variant="temporary"
            sx={{
              "& .MuiDrawer-paper": {
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingTop: "20px",
              },
            }}
    >
      <Grid container
            display="flex"
            justifyContent="center"
            alignItems="center">
        <Box component="img"
             src={Appdownload} />
        <Box>
          <Box display="flex"
               flexDirection="column"
               justifyContent="start">
            <Box display="flex">
              <Typography fontFamily="KBODiaGothic"
                          fontSize="20px"
                          fontWeight={500}
                          color="#000000">프레젬 앱</Typography>
              <Typography fontFamily="KBODiaGothic"
                          fontSize="20px"
                          fontWeight={500}
                          color="#FF6151">&nbsp;3초만에!</Typography>
            </Box>

            <Typography fontFamily="KBODiaGothic"
                        fontSize="20px"
                        fontWeight={500}
                        color="#000000">지금 바로 시작하기</Typography>
            <Typography fontFamily="KBODiaGothic"
                        fontSize="12px"
                        fontWeight={300}
                        color="#979797">핫한 팝업, 핫플정보 다 모았다</Typography>
          </Box>
        </Box>
      </Grid>

      <Grid container
            display="flex"
            justifyContent="center"
            alignItems="center">
        <Button endIcon={<Box component="img" src={ChevronRightWhite} />}
                size="large"
                sx={{
                  height: "50px",
                  backgroundColor: "#FF6151",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                  padding: "20px",
                }}
                onClick={() => window.location.replace(`${process.env.REACT_APP_DEEPLINK}`)}>
          <Typography fontFamily="Apple SD Gothic Neo"
                      fontSize="16px"
                      fontWeight={500}
                      color="#FFFFFF">앱 1분만에 다운받고 설탕받기</Typography>
        </Button>
      </Grid>

      <Grid container
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={1}
            mb={4}>
        <Button endIcon={<Box component="img" src={ChevronRightWhite} />}
                size="large"
                onClick={hideDrawer}
                sx={{
                  height: "50px",
                  backgroundColor: "transparent",
                  borderRadius: "8px",
                  color: "#A9A9A9",
                  padding: "20px",
                }}>
          <Typography fontFamily="Apple SD Gothic Neo"
                      fontSize="14px"
                      fontWeight={500}
                      color="#A9A9A9">괜찮아요 웹으로 볼게요</Typography>
        </Button>
      </Grid>
    </Drawer>
  );
}