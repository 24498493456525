const FavoriteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M8.39984 2.33325H19.5998C20.59 2.33325 21.5396 2.64054 22.2397 3.18752C22.9398 3.7345 23.3332 4.47637 23.3332 5.24992V25.6666L13.9998 21.2916L4.6665 25.6666V5.24992C4.6665 4.47637 5.05984 3.7345 5.75997 3.18752C6.46011 2.64054 7.4097 2.33325 8.39984 2.33325Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FavoriteIcon;
