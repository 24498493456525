const ViewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M14 18.3334C8.18887 18.3334 3.22069 14.8881 1.08422 10.0001C3.22069 5.1121 8.18887 1.66675 14 1.66675C19.8111 1.66675 24.7793 5.1121 26.9158 10.0001C24.7793 14.8881 19.8111 18.3334 14 18.3334Z"
        stroke="#F8F8F8"
        strokeWidth="2"
      />
      <circle cx="14" cy="10" r="4.25" stroke="#F8F8F8" strokeWidth="2" />
    </svg>
  );
};

export default ViewIcon;
