const CommentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
    >
      <path
        d="M26.3684 16.0694C26.3684 16.068 26.3689 16.0671 26.3689 16.0657C26.6526 14.8698 26.8332 13.1035 26.8332 11.1289C26.8332 9.15426 26.6526 7.38847 26.3689 6.19255C26.3689 6.19116 26.3684 6.19023 26.3684 6.18884C26.2178 5.55492 26.0377 5.0862 25.8401 4.8305C25.1241 3.53295 23.8747 2.51571 22.3503 2.01265C22.1651 1.93237 21.8773 1.85626 21.4945 1.78572C21.4646 1.78015 21.4342 1.77598 21.4037 1.77041C19.9108 1.50914 17.1566 1.33325 13.997 1.33325C10.8368 1.33325 8.08265 1.50914 6.58975 1.77041C6.55932 1.77598 6.52888 1.78015 6.49844 1.78572C6.11568 1.85626 5.82782 1.93237 5.64263 2.01265C4.10898 2.51942 2.85285 3.54641 2.13942 4.85602C1.96506 5.09595 1.80462 5.50248 1.66689 6.04359C1.64368 6.13269 1.62201 6.22225 1.60395 6.31275C1.33622 7.50541 1.1665 9.21877 1.1665 11.1289C1.1665 13.0395 1.33622 14.7524 1.60344 15.945C1.62201 16.036 1.64368 16.126 1.66689 16.2151C1.80462 16.7558 1.96506 17.1623 2.13942 17.4022C3.03031 19.0376 4.76825 20.2335 6.83375 20.5212V24.051C6.83375 24.496 7.30319 24.7935 7.72 24.613L14.486 20.5946H20.1997C22.6671 20.5946 24.8084 19.2984 25.8401 17.4277C26.0377 17.172 26.2178 16.7033 26.3684 16.0694"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M8.22461 8.42098H19.8913"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.22461 14.2542H19.8913"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CommentIcon;
