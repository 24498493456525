import 'assets/scss/_global.scss';
import Map from 'pages/Home/components/Map';

import App from 'App';
import { store } from 'app/store';
import Post from 'pages/Post';
import PostDetail from 'pages/Post/PostDetail';
import Question from 'pages/Question';
import QuestionAndPost from 'pages/QuestionAndPost';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Share from 'pages/Share';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Search from './pages/Search';
import PopupStores from './pages/PopupStores';
import Introduces from './pages/Introduce';
import PopupStoresDetail from './pages/PopupStores/PopupStoresDetail';
import ToOpenPopupStores from './pages/PopupStores/ToOpenPopupStores';
import RakingPopupStores from './pages/PopupStores/Rankings';

const container = document.getElementById('root')!;
const root = createRoot(container);

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&:hover': {}
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontWeight: 800,
          lineHeight: 'normal',
          fontSize: '16px',
          fontStyle: 'normal',
          fontFamily: 'Apple SD Gothic Neo',
          borderRadius: '8px',
          backgroundColor: '#FFF',
          padding: '5px 10px 2px'
        }
      }
    }
  }
});
const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: <Map />,
        children: [
          {
            element: <Search />,
            path: 'search'
          },
          {
            element: <QuestionAndPost />,
            path: 'question-post'
          },
          {
            element: <Question />,
            path: 'questions'
          },
          {
            element: <Post />,
            path: 'posts',
            children: [
              {
                element: <PostDetail />,
                path: ':id'
              }
            ]
          },
          {
            element: <PopupStores />,
            path: 'popup-stores',
            children: [
              {
                element: <RakingPopupStores />,
                path: 'rankings',
              },
              {
                element: <ToOpenPopupStores />,
                path: 'to-opens',
              },
              {
                element: <PopupStoresDetail />,
                path: ':id',
              },
            ],
          },
          {
            element: <Introduces />,
            path: 'introduces',
          }
        ]
      }
    ]
  },
  {
    path: '/share',
    element: <Share />
  }
]);
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
