import React from 'react';

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="187"
      height="36"
      viewBox="0 0 187 36"
      fill="none"
    >
      <path
        d="M0 4.47579C0 2.33552 1.75412 0.599609 3.91682 0.599609H13.3637C20.6478 0.599609 26.7274 6.03044 26.7274 12.8695C26.7274 19.7086 20.7817 25.2858 13.3637 25.2858H9.3905V31.7206C9.3905 33.8608 7.63639 35.5967 5.47369 35.5967H3.91682C1.75412 35.5967 0 33.8608 0 31.7206L0 4.47579ZM17.6609 12.9113C17.6609 10.785 15.8645 9.1746 13.3637 9.1746H9.3905V16.7108L13.3637 16.7318C15.8857 16.7527 17.6609 15.0586 17.6609 12.9113Z"
        fill="#FF6151"
      />
      <path
        d="M48.4527 33.7563L40.1471 20.3988V31.7206C40.1471 33.8608 38.393 35.5967 36.2303 35.5967H34.6664C32.5037 35.5967 30.7495 33.8678 30.7495 31.7275L30.7073 0.599609H44.1344C51.7707 0.599609 57.2303 5.70278 57.2303 11.9772C57.2303 16.3413 54.5745 19.9875 51.1086 21.5491L56.5188 29.5664C58.2589 32.1389 56.392 35.5967 53.2642 35.5967H51.7848C50.4252 35.5967 49.1642 34.8996 48.4527 33.7563ZM40.0978 15.9719L43.9371 15.9928C45.9307 16.0137 47.7553 14.6263 47.7553 12.4721C47.7553 10.3179 45.9096 8.95151 43.9371 8.95151H40.0978V15.9649V15.9719Z"
        fill="#FF6151"
      />
      <path
        d="M73.25 9.24432V14.1314H80.5693C82.732 14.1314 84.4862 15.8673 84.4862 18.0075C84.4862 20.1478 82.732 21.8837 80.5693 21.8837H73.25V26.8963H82.9786C85.1413 26.8963 86.8954 28.6322 86.8954 30.7724V31.7275C86.8954 33.8678 85.1413 35.6037 82.9786 35.6037H63.9722V0.599609H82.9786C85.1413 0.599609 86.8954 2.33552 86.8954 4.47579V5.36814C86.8954 7.50841 85.1413 9.24432 82.9786 9.24432H73.25Z"
        fill="#FF6151"
      />
      <path
        d="M102.838 9.28615H94.7999C92.6372 9.28615 90.8831 7.55023 90.8831 5.40997V4.47579C90.8831 2.33552 92.6372 0.599609 94.7999 0.599609H117.625L105.691 26.8684H113.708C115.87 26.8684 117.625 28.6043 117.625 30.7445V31.7206C117.625 33.8608 115.87 35.5967 113.708 35.5967H90.8831L102.838 9.27918V9.28615Z"
        fill="#FF6151"
      />
      <path
        d="M133.651 9.24432V14.1314H140.97C143.133 14.1314 144.887 15.8673 144.887 18.0075C144.887 20.1478 143.133 21.8837 140.97 21.8837H133.651V26.8963H143.379C145.542 26.8963 147.296 28.6322 147.296 30.7724V31.7275C147.296 33.8678 145.542 35.6037 143.379 35.6037H124.373V0.599609H143.379C145.542 0.599609 147.296 2.33552 147.296 4.47579V5.36814C147.296 7.50841 145.542 9.24432 143.379 9.24432H133.651Z"
        fill="#FF6151"
      />
      <path
        d="M186.923 4.62213C186.972 4.37116 187 4.11321 187 3.84829C187 1.72197 185.26 0 183.111 0C181.724 0 180.512 0.72504 179.829 1.80563C179.8 1.84746 179.758 1.88232 179.73 1.92414L170.1 17.6101L160.47 1.92414C160.442 1.88232 160.4 1.84746 160.371 1.80563C159.681 0.72504 158.476 0 157.089 0C154.94 0 153.2 1.72197 153.2 3.84829C153.2 4.11321 153.228 4.37116 153.277 4.62213C153.235 4.88705 153.193 5.15197 153.193 5.43083V31.0791C153.193 32.3479 153.714 33.5052 154.56 34.3418C155.405 35.1784 156.56 35.6943 157.856 35.6943C160.435 35.6943 162.52 33.6168 162.52 31.0791V20.0013L170.072 30.9188H170.114L177.666 20.0013V31.0791C177.666 33.6168 179.751 35.6943 182.33 35.6943C183.626 35.6943 184.781 35.1784 185.626 34.3418C186.472 33.5052 186.993 32.3479 186.993 31.0791V5.43083C186.993 5.15197 186.958 4.88705 186.909 4.62213H186.923Z"
        fill="#FF6151"
      />
    </svg>
  );
};

export default Logo;