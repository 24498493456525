import { useNavigate } from 'react-router-dom';
import MapLayoutWithHeader from '../../../layouts/MapLayoutWithHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import { ButtonBase } from '@mui/material';
import Box from '@mui/material/Box';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Button from '@mui/material/Button';
import Sugar from '../../../assets/images/ic_sugar.svg';
import { PopupStore } from '../../../services/presentation/PopupStore';
import { FetchPopupStores } from '../../../services/popup-stores/fetchPopupStores';
import ToOpenPopupStore from '../components/ToOpenPopupStore';

const useToOpenPopupStores = () => {
  const navigate = useNavigate();
  const [toOpenPopupStores, setToOpenPopupStores] = useState<PopupStore[]>([]);


  useEffect(() => {
    async function fetchToOpenPopupStores() {
      const popupStores = await FetchPopupStores(true);
      setToOpenPopupStores(popupStores);
    }

    fetchToOpenPopupStores();
  }, []);

  return {
    models: {
      toOpenPopupStores,
    },
    operations: {
      navigate,
    },
  };
}

export default function ToOpenPopupStores() {
  const { models, operations } = useToOpenPopupStores();

  return (
    <MapLayoutWithHeader title="오픈예정 팝업스토어"
                         onClickBackButton={() => operations.navigate(-1)}>
      <Grid container
            alignItems="baseline"
            mb="16px">
        <Grid item>
          <Typography fontFamily="Apple SD Gothic Neo"
                      fontSize="18px"
                      fontWeight={700}
                      lineHeight="21.6px"
                      color="#000000">오픈 예정 팝업스토어 ⏰</Typography>
        </Grid>
      </Grid>

      <Grid container
            spacing={2}>
        {models.toOpenPopupStores.length > 0 &&
          models.toOpenPopupStores.map((popupStore: PopupStore) => {
              const { id, thumbnailImages, name, isPreReservation, address } = popupStore;
              const addressSplit = address.split(' ');

              return (
                <Grid item
                      xs={6}>
                  <ButtonBase key={id}
                              onClick={() => operations.navigate(`/popup-stores/${id}`)}
                              sx={{
                                marginBottom: '10px',
                                width: '100%',
                              }}>
                    <Box sx={{
                      width: '100%',
                    }}>
                      <ToOpenPopupStore background={thumbnailImages[0]}
                                        width="100%">
                        <Box justifyContent="space-between"
                             flexDirection="column"
                             display="flex"
                             position="absolute"
                             zIndex={20}
                             width="100%"
                             height="100%">
                          <Box alignSelf="flex-end"
                               mt={1}
                               mr={1}
                               sx={{
                                 opacity: isPreReservation ? 1 : 0,
                               }}>
                            <Button sx={{
                              backgroundColor: '#FFFFFF',
                              borderRadius: '18px',
                              "&:hover": {
                                backgroundColor: '#FFFFFF',
                              },
                            }}>
                              <Typography fontFamily="Apple SD Gothic Neo"
                                          fontSize="9px"
                                          fontWeight={400}
                                          color="#000000"
                                          lineHeight="10.8px">🎟️ 사전예약</Typography>
                            </Button>
                          </Box>
                        </Box>
                      </ToOpenPopupStore>
                    </Box>
                  </ButtonBase>

                  <Typography fontFamily="Apple SD Gothic Neo"
                              fontSize="14px"
                              fontWeight={600}
                              lineHeight="16.8px"
                              color="#000000">{name}</Typography>
                  <Typography fontFamily="Apple SD Gothic Neo"
                              fontSize="12px"
                              fontWeight={400}
                              lineHeight="14.4px"
                              mt={1}
                              color="#6F6F6F">
                    <LocationOnIcon fontSize="inherit" />{addressSplit[0]} {addressSplit[1]}
                  </Typography>
                </Grid>
              );
            })}
      </Grid>
    </MapLayoutWithHeader>
  );
}