import React from 'react';

const IconQuestionContent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
    >
      <path
        d="M8.40708 8.60323C9.30572 7.772 9.8651 6.64132 9.97859 5.42668C10.0921 4.21204 9.75174 2.99838 9.02246 2.01701C8.29317 1.03565 7.22592 0.355188 6.02412 0.10533C4.82232 -0.144528 3.57 0.0536852 2.50583 0.662191C1.44167 1.2707 0.640081 2.24695 0.253836 3.40488C-0.132408 4.56281 -0.0762993 5.82145 0.41147 6.94093C0.899239 8.0604 1.78456 8.96242 2.8987 9.47508C4.01285 9.98774 5.2779 10.0752 6.45277 9.72074L6.87142 10.4409C7.02127 10.6976 7.26742 10.8849 7.55598 10.9618C7.84454 11.0386 8.15199 10.9987 8.41098 10.8508C8.66924 10.7018 8.85759 10.4573 8.93483 10.1707C9.01207 9.88416 8.97191 9.57886 8.82313 9.32163L8.40708 8.60323ZM2.01171 4.95084C2.01171 4.3649 2.1866 3.79212 2.51427 3.30493C2.84193 2.81774 3.30766 2.43804 3.85255 2.21382C4.39743 1.98961 4.997 1.93097 5.57544 2.04532C6.15387 2.15966 6.68518 2.44185 7.10218 2.8562C7.51918 3.27056 7.80314 3.79846 7.91815 4.37315C8.03316 4.94784 7.97404 5.54351 7.74829 6.08483C7.52253 6.62614 7.14027 7.08879 6.64986 7.41426C6.15944 7.73973 5.58289 7.9134 4.99311 7.91331C4.60155 7.91331 4.21383 7.83668 3.85208 7.6878C3.49033 7.53892 3.16164 7.32069 2.88479 7.0456C2.60793 6.7705 2.38833 6.44392 2.23853 6.0845C2.08872 5.72507 2.01165 5.33986 2.01171 4.95084Z"
        fill="#FF6151"
      />
    </svg>
  );
};

export default IconQuestionContent;