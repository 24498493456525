const LikeIcon= () => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
      >
        <path
          d="M13.9998 25.5502C15.2832 25.5502 26.8332 19.1038 26.8332 10.0787C26.8332 3.10048 18.0584 -0.550687 13.9998 5.56536C9.93433 -0.561173 1.1665 3.09417 1.1665 10.0787C1.1665 19.1038 12.7165 25.5502 13.9998 25.5502Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      );
}
 
export default LikeIcon;