import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';

import MapLayouts from '../../layouts/MapLayouts';
import BackButton from '../../components/BackButton/BackButton';
import { SearchInput } from './components/SearchInput/SearchInput';
import { useCallback, useEffect, useState } from 'react';
import { FetchPopupStoreRankings, PopupStoreRanking } from '../../services/popup-stores/fetchRankings';
import { RecommendButton } from './components/RecommendButton/RecommendButton';
import Box from '@mui/material/Box';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Button from '@mui/material/Button';
import Sugar from 'assets/images/ic_sugar.svg';
import { PopupStore } from '../../services/presentation/PopupStore';
import { FetchPopupStores } from '../../services/popup-stores/fetchPopupStores';
import { ButtonBase } from '@mui/material';

const useSearch = () => {
  const [params, setParams] = useSearchParams();
  const [popupStoreRankings, setPopupStoreRankings] = useState<PopupStoreRanking[]>([]);
  const [popupStores, setPopupStores] = useState<PopupStore[]>([]);
  const [filteredPopupStores, setFilteredPopupStores] = useState<PopupStore[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPopupStoreRankings() {
      const popupStores = await FetchPopupStoreRankings();
      setPopupStoreRankings(popupStores);
    }

    async function fetchPopupStores() {
      const popupStores = await FetchPopupStores();
      setPopupStores(popupStores);
    }

    fetchPopupStoreRankings();
    fetchPopupStores();
  }, []);

  useEffect(() => {
    const keyword = params.get('keyword');

    if (!keyword || popupStores.length === 0) {
      return;
    }

    setFilteredPopupStores(popupStores.filter((popupStore) => popupStore.name.includes(keyword)));
  }, [params, popupStores]);

  const onSearch = useCallback((keyword: string) => {
    if (!keyword || popupStores.length === 0) {
      return;
    }

    setFilteredPopupStores(popupStores.filter((popupStore) => popupStore.name.includes(keyword)));
  }, [popupStores]);

  return {
    models: {
      popupStoreRankings,
      filteredPopupStores,
    },
    operations: {
      navigate,
      onSearch,
    },
  };
}

export default function Search() {
  const { models, operations } = useSearch();

  return (
    <MapLayouts>
      <Grid container>
        <Grid item
              xs={1}>
          <Grid container
                justifyContent="center"
                alignItems="center"
                height="100%">
            <BackButton onClick={() => operations.navigate('/')} />
          </Grid>
        </Grid>
        <Grid item
              xs={11}
              pr={2}>
          <SearchInput placeholder="궁금한 팝업스토어를 검색해보세요"
                       onChange={(e) => operations.onSearch(e.target.value)}
                       InputProps={{
                         endAdornment: (<SearchIcon sx={{
                           marginRight: '10px',
                         }}/>),
                       }}/>
        </Grid>
      </Grid>

      {models.popupStoreRankings.length === 0 && (
        <Grid container
              mt={5}
              direction="column"
              justifyContent="center"
              alignItems="center">
          <Typography fontFamily="Apple SD Gothic Neo"
                      fontSize="16px"
                      fontWeight={700}>추천 위치를 불러오고 있습니다.</Typography>
        </Grid>
      )}

      {models.filteredPopupStores.length > 0 && models.filteredPopupStores.map((item: PopupStore, index) => {
        const { id, address, name } = item;

        return (
          <Grid container
                key={id}
                mt={`${index === 0 ? 20 : 0}px`}
                sx={{
                  borderBottom: '1px solid #E0E0E0',
                }}>
            <ButtonBase onClick={() => operations.navigate(`/popup-stores/${id}`)}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          gap: '5px',
                          paddingTop: '10px',
                          paddingBottom: '10px',
                        }}>
              <Grid item>
                <Box>
                  <Typography fontFamily="Apple SD Gothic Neo"
                              fontSize="14px"
                              fontWeight={600}>{name}</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <Typography fontFamily="Apple SD Gothic Neo"
                              fontSize="12px"
                              fontWeight={400}
                              color="#96969"
                              sx={{
                                width: '100%',
                                opacity: 0.6,
                              }}
                  ><LocationOnIcon fontSize="inherit" />{address}</Typography>
                </Box>
              </Grid>
            </ButtonBase>
          </Grid>
        );
      })}

      {models.popupStoreRankings.length > 0 && (
        <Grid container
              mt={5}
              direction="column">
          <Grid item>
            <Typography fontFamily="Apple SD Gothic Neo"
                        fontSize="16px"
                        fontWeight={700}>추천 위치</Typography>
          </Grid>
          <Grid item
                mt="5px"
                sx={{
                  width: '100%',
                  overflowX: 'scroll',
                  paddingBottom: '15px',
                }}>
            <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  }}>
              {models.popupStoreRankings.map((item: PopupStoreRanking, index) => (
                <RecommendButton key={item.id}
                                 onClick={() => operations.navigate(`/popup-stores/${item.id}`)}>{item.name}</RecommendButton>
              ))}
            </Box>
          </Grid>
        </Grid>
      )}

      {models.popupStoreRankings.length > 0 && (
        <Grid container
              spacing={1}>
          {models.popupStoreRankings.map((item: PopupStoreRanking) => {
            const { id, thumbnailImages, address, name, isAssociated } = item;
            const addressSplit = address.split(' ');

            return (
              <Grid item
                    xs={6}
                    key={id}
                    mb={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="start">
                <ButtonBase onClick={() => operations.navigate(`/popup-stores/${item.id}`)}
                            sx={{
                              width: '100%'
                            }}
                >
                  <Box display="flex"
                       flexDirection="column"
                       sx={{
                         width: 'inherit',
                         position: 'relative',
                       }}>
                    {isAssociated && (
                      <Button sx={{
                        position: 'absolute',
                        height: '24px',
                        fontFamily: 'Apple SD Gothic Neo',
                        fontSize: '9px',
                        fontWeight: 700,
                        color: 'black',
                        backgroundColor: 'white',
                        borderRadius: '18px',
                        top: 5,
                        right: 5,
                      }}><Box component="img" src={Sugar} />&nbsp;&nbsp;리워드</Button>
                    )}
                    <img src={thumbnailImages[0]}
                         alt={name}
                         style={{
                           borderRadius: '4px',
                           margin: 'auto',
                           objectFit: 'cover',
                           transform: 'translate(50, 50)',
                           aspectRatio: '1 / 1',
                           width: 'inherit',
                         }} />
                    <Box display="flex"
                         justifyContent="start"
                         mt={"8px"}>
                      <Typography fontFamily="Apple SD Gothic Neo"
                                  fontSize="14px"
                                  fontWeight={600}
                                  overflow="hidden"
                                  whiteSpace="nowrap"
                                  textOverflow="ellipsis">{name}</Typography>
                    </Box>
                    <Box display="flex"
                         justifyContent="start">
                      <Typography fontFamily="Apple SD Gothic Neo"
                                  fontSize="12px"
                                  fontWeight={400}
                                  color="#96969"
                                  sx={{
                                    opacity: 0.6,
                                  }}
                      ><LocationOnIcon fontSize="inherit" />{`${addressSplit[0]} ${addressSplit[1]}`}</Typography>
                    </Box>
                  </Box>
                </ButtonBase>
              </Grid>
            );
          })}
        </Grid>
      )}

    </MapLayouts>
  );
}