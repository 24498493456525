import { styled } from '@mui/material';
import Button from '@mui/material/Button';

export const RankingButton = styled(Button)({
  width: 'fit-content',
  height: '37px',
  padding: '10px 16px 10px 16px',
  border: '1px solid #FF6151',
  borderRadius: '24px',
  color: '#FF6151',
  font: 'Apple SD Gothic Neo',
  fontSize: '14px',
  fontWeight: 700,
  backgroundColor: '#FFF',

  '&:hover': {
    backgroundColor: '#FF6151',
    color: '#FFF',
  }
});
