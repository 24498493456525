import moment from 'moment';

export const calculateTimeAgo = (timestamp: string, isShowMDH?: boolean) => {
  const notificationTime = moment(timestamp).format('YYYY-MM-DD HH:mm');

  const currentTime = moment()
    .utcOffset(new Date().getTimezoneOffset() * -1)
    .format('YYYY-MM-DD HH:mm');

  const minutesDifference = Math.abs(moment(notificationTime).diff(moment(currentTime), 'minutes'))
  if (minutesDifference < 60) {
    return `${Math.floor(minutesDifference)}분전`; // phút trước
  }
  if (minutesDifference < 1440) {
    return `${Math.floor(minutesDifference / 60)}시간전`; // giờ trước
  }
  if (minutesDifference < 43200) {
   return isShowMDH ? moment(notificationTime).format('M월 D일 H시') : // 8월 18일 13시

      `${Math.floor(minutesDifference / 1440)}일전`; // ngày trước
  } else {
    return moment(notificationTime).format('M월 D일 H시'); // 8월 18일 13시

    // return moment(notificationTime).format('YYYY.MM.DD');
  }
}