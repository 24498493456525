import { ReactNode } from 'react';
import { Stack } from '@mui/material';

interface MapLayoutsProps {
  children: ReactNode;
}

const MapLayouts = ({ children }: MapLayoutsProps) => {
  return (
    <Stack paddingTop="20px"
           paddingLeft="10px"
           paddingRight="10px"
           sx={{
             backgroundColor: '#fff !important',
             height: '100svh !important',
             overflow: 'auto',
           }}>
      {children}
    </Stack>
  );
}

export default MapLayouts;
