import axios, { AxiosInstance } from 'axios';

export interface CoreResponse {
  statusCode: number;
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  headers: {
    'Content-Type': 'application/json'
  },
});

export default axiosInstance;
