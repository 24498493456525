export const openDeepLink = () => { 
  let link = 'https://apps.apple.com/us/app/prezem-프레젬/id6446088313';

  if (
    window.navigator.userAgent.includes('iPad') ||
    window.navigator.userAgent.includes('iPhone')
  ) {
    window.location.replace('/share')
  } else {
    window.open(link);
  }

}