import { Backdrop, CircularProgress } from '@mui/material';
import { RootState } from 'app/store';
import './loading.scss';
import { useSelector } from 'react-redux';

const Loading = () => {
  const _isLoading = useSelector((state: RootState) => state.loading);

  return (
    <Backdrop
      sx={{
        color: '#FF6151',
        zIndex: 9999,
        position: 'absolute',
        height: '100%',
        width: '100%',
        left: '50%',
        transform: 'translate(-50%, 0)'
      }}
      open={_isLoading.loading}
    >
      <div className="loader"></div>
    </Backdrop>
  );
};

export default Loading;
