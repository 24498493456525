import { Button } from '@mui/material';

import './style.css';

type ListFeedButtonType = {
  onClick: () => void;
}

const ListFeedButton = ({ onClick }: ListFeedButtonType) => {
  return (
    <Button onClick={onClick}
            className="button-feed">
      팝업 둘러보기
    </Button>
  );
};

export default ListFeedButton;
