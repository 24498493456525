import IconButton from '@mui/material/IconButton';
import { Box, styled } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronWhiteLeft from 'assets/icons/btn_Backk.png';

const BackIconButton = styled(IconButton)({
  cursor: 'pointer',
  color: '#100d0d',
  borderRadius: '100px',
  flexShrink: 0,
});

interface BackButtonProps {
  onClick: () => void;
  isWhite?: boolean;
}

export default function BackButton({
  onClick,
  isWhite,
}: BackButtonProps) {
  return (
    <BackIconButton onClick={onClick}>
      {isWhite ? (<Box component="img"
                       src={ChevronWhiteLeft}
                       width="44px"
        />) :
        (<ChevronLeftIcon fontSize="medium" />)}
    </BackIconButton>
  );
}