import axiosInstance from '../axiosInstance';

export const Access = async function() {
  try {
    await axiosInstance.post(
      'v2/access',
      {
        uuid: 'web',
      },
    );
  } catch {}
}