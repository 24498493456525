import Grid from '@mui/material/Grid';
import BackButton from '../components/BackButton/BackButton';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';

interface MapLayoutWithHeaderProps {
  onClickBackButton: () => void;
  title: string;
  children: React.ReactNode;
}

export default function MapLayoutWithHeader({
  onClickBackButton,
  title,
  children,
}: MapLayoutWithHeaderProps) {
  return (
    <Stack sx={{
             backgroundColor: '#fff !important',
             height: '100svh !important',
             overflow: 'auto',
             zIndex: 100,
           }}>
      <Grid container
            justifyContent="space-between"
            sx={{
              backgroundColor: '#fff',
              position: 'sticky',
              top: '0',
              padding: '20px 10px 10px 10px',
              borderBottom: '1px solid #E0E0E0',
              zIndex: 100,
            }}>
        <Grid item>
          <Grid container
                justifyContent="center"
                alignItems="center"
                height="100%">
            <BackButton onClick={onClickBackButton} />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container
                justifyContent="center"
                alignItems="center"
                height="100%">
            <Typography fontFamily="Apple SD Gothic Neo"
                        fontSize="16px"
                        fontWeight={700}
                        lineHeight="31.2px"
                        color="#000000">{title}</Typography>
          </Grid>
        </Grid>
        <Grid item
              sx={{
                opacity: 0,
              }}>
          <Grid container
                justifyContent="space-between"
                alignItems="center"
                height="100%">
            <BackButton onClick={() => {}} />
          </Grid>
        </Grid>
      </Grid>
      <Stack padding="20px 10px"
              sx={{
                backgroundColor: '#fff',
                zIndex: 100,
              }}>
        {children}
      </Stack>
    </Stack>
  );
}