import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { useAppSelector } from 'app/hooks';
import qna from 'assets/images/ic_question.svg';
import DialogDownload from 'components/Dialog/DialogDownload';
import HotMomentIcon from 'components/IconSVG/HotMomentIcon';
import IconQuestionContent from 'components/IconSVG/IconQuestionContent';
import LocationIcon from 'components/IconSVG/LocationIcon';
import SugarIcon from 'components/IconSVG/SugarIcon';
import { setLoading } from 'components/Loading/loadingSlice';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReactShowMoreText from 'react-show-more-text';
import PlaceService from 'services/place.service';
import { postInitialState } from 'types/entities/post';
import { questionInitialState } from 'types/entities/question';
import { PostState } from 'types/post.interface';
import { QuestionState } from 'types/question.interface';
import { calculateTimeAgo } from 'utils/formartTime';
import { openDeepLink } from 'utils/openDeepLink';
import NoData from '../../components/NoData';
import './styles.scss';

type ParamsType = {
  longtitude: string | null;
  latitude: string | null;
  radius: string | null;
  page: number;
  limit: number;
};

const QuestionAndPost = () => {
  const [searchParams] = useSearchParams();
  const [pageQuestion, setPageQuestion] = useState<number>(1);
  const [pagePost, setPagePost] = useState<number>(1);
  const [params, setParams] = useState<ParamsType>({
    longtitude: searchParams.get('lng'),
    latitude: searchParams.get('lat'),
    radius: searchParams.get('radius'),
    page: 1,
    limit: 10
  });
  const dispatch = useDispatch();
  const [dataQuestion, setDataQuestion] =
    useState<QuestionState>(questionInitialState);
  const [dataPost, setDataPost] = useState<PostState>(postInitialState);
  const [modalDownload, setModalDownload] = useState<boolean>(false);

  const loading = useAppSelector((store) => store.loading);
  const navigate = useNavigate();
  const questionRef = useRef<HTMLDivElement>(null);
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);

  const handleToFeedDetail = (id: number) => {
    navigate({
      pathname: `/posts/${id}`
    });
  };
  useEffect(() => {
    handleGetPostList();
    //@typescript-eslint/no-unused-vars
  }, [pagePost]);
  useLayoutEffect(() => {
    handleGetQuestionLists();
    //@typescript-eslint/no-unused-vars
  }, [pageQuestion]);

  const handleNextPagePost = () => {
    if (pagePost < dataPost.meta.pagination.total_pages) {
      setPagePost((pre) => pre + 1);
    }
  };

  const handleGetQuestionLists = async () => {
    dispatch(setLoading(true));
    const res: any = await PlaceService.getQuestionLists({
      ...params,
      page: pageQuestion
    });

    setDataQuestion((prevData) => {
      return {
        ...prevData,
        meta: res.meta,
        data: {
          questions: [...prevData?.data?.questions, ...res?.data?.questions]
        }
      };
    });
    dispatch(setLoading(false));
  };

  useEffect(() => {
    const handleScroll = () => {
        if (
        questionRef.current &&
        questionRef.current.scrollLeft ===
          questionRef.current.scrollWidth - questionRef.current.clientWidth
      ) {   
        setIsEndOfScroll(true);
      } else {
        setIsEndOfScroll(false);
      }
    };
    if (questionRef.current) {
      questionRef.current.addEventListener(
        'scroll',
        handleScroll
      );
    }
    return () => {
      if (questionRef.current) {
        questionRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [questionRef.current]);

  useEffect(() => {
    if (
      isEndOfScroll &&
      pageQuestion < dataQuestion.meta.pagination.total_pages
    ) {
      setPageQuestion((pre) => pre + 1);
    }
  }, [isEndOfScroll]);
  const handleGetPostList = async () => {
    dispatch(setLoading(true));
    const res: any = await PlaceService.getPostLists({
      ...params,
      page: pagePost
    });
    setDataPost((prevData) => {
      return {
        ...prevData,
        meta: res.meta,
        data: {
          posts: [...prevData.data.posts, ...res.data.posts]
        }
      };
    });
    dispatch(setLoading(false));
  };

  return (
    <>
      <Box className="place-content">
        <Box className="navigate">
          <IconButton className="button-back" onClick={() => navigate(-1)}>
            <ChevronLeftIcon fontSize="medium" />
          </IconButton>
        </Box>

        {dataPost.data.posts.length > 0 ||
        dataQuestion.data.questions.length > 0 ? (
          <Box>
            <Box className="question-header">
              <Box className="left-question">질문</Box>
              <Box className="right-question">
                <Box className="icon" component={'img'} src={qna} />
                <Box className="question-total">{`${dataQuestion.meta.pagination.total}개의 질문`}</Box>
              </Box>
            </Box>
            <Box className="question-content" ref={questionRef}>
              {dataQuestion?.data?.questions.map((item, idx) => (
                <Box ml={idx === 0 ? '16px' : 'unset'}>
                  <Card
                    className="card"
                    key={item.id}
                    sx={{
                      mr:
                        idx === dataQuestion.data.questions.length - 1
                          ? '16px'
                          : '8px'
                    }}
                  >
                    <Box
                      className="card-content"
                      onClick={() => setModalDownload(true)}
                    >
                      <Box className="card-content-header">
                        <Box className="card-content-header-left">
                          <LocationIcon className="card-content-header-left-icon" />
                          <Box className="card-content-header-left-title">
                            {item.locationName}
                          </Box>
                        </Box>
                        <Box className="card-content-header-right">{`${item.answerCount}분전`}</Box>
                      </Box>
                      <Box className="card-content-body">
                        <Box className="card-content-body-icon">
                          <IconQuestionContent />
                        </Box>
                        <Box className="card-content-body-text">
                          <ReactShowMoreText
                            /* Default options */
                            more="더보기"
                            less="숨기기"
                            lines={2}
                            //@ts-ignore
                            expandByClick={false}
                            width={190}
                            truncatedEndingComponent={'...'}
                          >
                            {item.content}
                          </ReactShowMoreText>
                        </Box>
                      </Box>
                      <Box className="card-content-footer">
                        <Box className="card-content-footer-sugar">
                          <Box className="sugar-icon">
                            <SugarIcon />
                          </Box>
                          <Box className="sugar-count">{item.sugar}</Box>
                        </Box>
                        <Box className="card-content-footer-more">
                          <Box className="text">응답</Box>
                          <Box className="number">{item.answerCount}</Box>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              ))}
              {/* </InfiniteScroll> */}
            </Box>
            <Divider className="divider" />
            <Box className="moment-header">
              <Box className="moment-header-left">순간</Box>
              <Box className="moment-header-right">
                <Box className="moment-header-right-icon">
                  <HotMomentIcon />
                </Box>
                <Box className="moment-header-right-text">{`${dataPost.meta.pagination.total}개의 순간`}</Box>
              </Box>
            </Box>

            <Box
              id="posts"
              className={`${
                dataQuestion.data.questions.length
                  ? 'moment'
                  : 'moment-no-question'
              } `}
            >
              <InfiniteScroll
                scrollableTarget="posts"
                hasMore={true}
                loader={''}
                dataLength={dataPost.data.posts.length}
                next={handleNextPagePost}
              >
                <Box className="moment-content" id="post-lists">
                  {dataPost?.data?.posts.map((item) => (
                    <Box className="moment-content-img" key={item.id}>
                      <div className="moment-content-img-time">{`${calculateTimeAgo(
                        item.createdAtImage
                      )}`}</div>
                      <img
                        loading="lazy"
                        onClick={() => handleToFeedDetail(item.id)}
                        src={item.imageUrl}
                        alt=""
                        className="moment-content-img-src"
                      />
                    </Box>
                  ))}
                </Box>
              </InfiniteScroll>
            </Box>
          </Box>
        ) : loading.loading ? (
          <></>
        ) : (
          <NoData onClick={() => setModalDownload(true)}></NoData>
        )}
      </Box>

      <DialogDownload
        handleClose={() => setModalDownload(false)}
        handleOk={openDeepLink}
        open={modalDownload}
      />
    </>
  );
};

export default QuestionAndPost;
