import { Box, Button } from '@mui/material';
import './no-data.scss';
const NoData = ({onClick} : {onClick: () => void}) => {
  return (
    <Box>
      <Box className="no-data">
        <Box>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="38"
            viewBox="0 0 42 38"
            fill="none"
          >
            <path
              d="M40.2757 23.7358C40.2757 23.7336 40.2765 23.7322 40.2765 23.73C40.7186 21.8849 41 19.1598 41 16.1133C41 13.0667 40.7186 10.3423 40.2765 8.49721C40.2765 8.49506 40.2757 8.49363 40.2757 8.49148C40.0409 7.51343 39.7603 6.79027 39.4524 6.39576C38.3366 4.39383 36.3894 2.82436 34.0138 2.04822C33.7252 1.92435 33.2766 1.80693 32.68 1.6981C32.6334 1.68951 32.586 1.68306 32.5385 1.67447C30.2119 1.27136 25.9197 1 20.9956 1C16.0706 1 11.7784 1.27136 9.45181 1.67447C9.40438 1.68306 9.35695 1.68951 9.30952 1.6981C8.71299 1.80693 8.2644 1.92435 7.97578 2.04822C5.58567 2.83009 3.62808 4.41459 2.51623 6.43514C2.2445 6.80531 1.99447 7.43252 1.77982 8.26737C1.74364 8.40484 1.70988 8.54303 1.68174 8.68265C1.2645 10.5228 1 13.1662 1 16.1133C1 19.061 1.2645 21.7038 1.68094 23.5439C1.70988 23.6842 1.74364 23.8231 1.77982 23.9606C1.99447 24.7947 2.2445 25.422 2.51623 25.7921C3.90463 28.3153 6.6131 30.1604 9.83208 30.6043V36.0502C9.83208 36.7369 10.5637 37.1958 11.2132 36.9173L21.7577 30.7175H30.6621C34.5074 30.7175 37.8445 28.7177 39.4524 25.8315C39.7603 25.437 40.0409 24.7138 40.2757 23.7358"
              stroke="#969696"
              strokeWidth="1.5"
            />
            <ellipse
              cx="11.5952"
              cy="15.4906"
              rx="2.042"
              ry="2.02158"
              fill="#969696"
            />
            <ellipse
              cx="21.1245"
              cy="15.4906"
              rx="2.042"
              ry="2.02158"
              fill="#969696"
            />
            <ellipse
              cx="30.6538"
              cy="15.4906"
              rx="2.042"
              ry="2.02158"
              fill="#969696"
            />
          </svg>
        </Box>
        <Box className="content">
          아직 올라온 질문과 순간이 없어요! <br />첫 업로드를 해보세요.
        </Box>

        <Box>
          <Button onClick={onClick} className="button-open-app">
            업로드 하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NoData;
