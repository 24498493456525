import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import './dialog-download.scss';
import Box from '@mui/material/Box';
const DialogDownload = ({
  open,
  handleClose,
  handleOk
}: {
  open: boolean;
  btnText?: string;
  handleClose: () => void;
  handleOk: () => void;
}) => {
  const handleConfirm = () => {
    handleOk();
    handleClose();
  };

  const handleCloseDialog = (event: {}, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        sx={{
          '.MuiDialog-paper': {
            margin: 'unset',
            borderRadius: '8px'
          }
        }}
      >
        <Box className="dialog-download">
          <Box className="title">프레젬을 다운로드 해주세요.</Box>
          <Box className="content">
            <Box>
              실시간 핫플정보부터 유저들의 <br />
              진짜 후기를 둘러보러 가볼까요?
            </Box>
          </Box>
          <Box className="button-download">
            <Button className="button-ok" onClick={handleConfirm}>
              확인
            </Button>
            <Button className="button-close" onClick={handleClose}>
              취소
            </Button>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};
export default DialogDownload;
