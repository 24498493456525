import { PostDetailState, PostState } from "types/post.interface";

export const postInitialState : PostState  = {
  data: {
    posts: []
  },
  meta: {
    code: 0,
    messages: [],
    pagination: {
      current_page: 1,
      from: 0,
      to: 0,
      total: 0,
      total_pages: 0,
      per_page: 0
    }
  }
}

export const postDetailInitialState: PostDetailState = {
  data: {
    post: {
      location: {
        id: 0,
        name: "",
        address: ""
      },
      user: {
        id: 0,
        nickname: "",
        alias: "",
        avatar: "",
        backgroundColorCode: ""
      },
      content: "",
      id: 0,
      created_at: "",
      totalLikes: 0,
      totalComments: 0,
      images: [],
      imageUrl: "",
      createdAtImage: "",
      isLiked: false,
      isHide: false,
      totalViews: 0,
      totalBookMarks: 0,
      isBookmarked: false
    }
  },
  meta: {
    code: 0,
    messages: [],
    pagination: {
      current_page: 1,
      from: 0,
      to: 0,
      total: 0,
      total_pages: 0,
      per_page: 0
    }
  }
}