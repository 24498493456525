import axiosInstance, { CoreResponse } from '../axiosInstance';

interface FetchPopupStoreRankingsResponse extends CoreResponse {
  popupStores: PopupStoreRanking[];
}

export interface PopupStoreRanking {
  id: number;
  name: string;
  address: string;
  thumbnailImages: string[];
  startDate: string;
  endDate: string;
  viewCount: number;
  isAssociated: boolean;
  isPreReservation: boolean;
}

export const FetchPopupStoreRankings = async function() {
  try {
    const { data } = await axiosInstance.get<FetchPopupStoreRankingsResponse>('v2/popup-stores/rankings');

    return data.popupStores;
  } catch {
    return [];
  }
}
