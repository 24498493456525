import { QuestionState } from "types/question.interface";

export  const questionInitialState: QuestionState = {
  data: {
    questions: []
  },
  meta: {
    code: 0,
    messages: [],
    pagination: {
      current_page: 1,
      from: 0,
      to: 0,
      total: 0,
      total_pages: 0,
      per_page: 0
    }
  }
}