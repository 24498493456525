import api from './api';


const getAllLists = async (params: {
  longtitude: number;
  latitude: number;
  radius: number | undefined;
}) => {
  const res = await api.get('location/byRadius', {
    params: params
  });
  return res.data
};
const getCurrentLocation = async (longtitude: number | undefined, latitude: number | undefined) => {
  const res = await api.get('location/current-detail', {
    params: {
     longtitude: longtitude,
     latitude: latitude
     

    },
   
  })
  return res
};
const getLocationLists = async (params: {}
) => {
  const res = await api.get('location/byRadius/', {
    params: params
  });
  return res.data
};
const getQuestionDetail = async (params: {}
) => {
  const res = await api.get('location/questions', {
    params: params
  });
  return res
};
const getPostDetail = async (params: {}
) => {
  const res = await api.get('location/posts', {
    params: params
  });
  return res
};
const getQuestionLists = async (params: {}
) => {
  const res = await api.get('location/questions/byRadius', {
    params: params
  });
  return res
};
const getPostLists = async (params: {}
) => {
  const res = await api.get('location/posts/byRadius', {
    params: params
  });
  return res
};
const getLocationPost = async (params: {}
) => {
  const res = await api.get('location/address/byCoordinate', {
    params: params
  });
  return res
};
const getPostDetailId = async (postId: number | undefined | string
) => {
  const res = await api.get(`post`, {
    params: {
      postId
    }
  });
  return res
};
const PlaceService = {
  getAllLists,
  getCurrentLocation,
  getLocationLists,
  getQuestionLists,
  getPostLists,
  getPostDetail,
  getLocationPost,
  getPostDetailId,
  getQuestionDetail
};

export default PlaceService;
