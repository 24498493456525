import { styled, TextField } from '@mui/material';

export const SearchInput = styled(TextField)({
  width: '353px',
  height: '48px',
  '& label': {
    // placeholder text color
    color: '#969696'
  },
  '& label.Mui-focused': {
    color: '#969696',
  },
  '& label.Mui-error': {
    color: '#d32f2f',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#fff',
    '& fieldset': {
      borderColor: '#ccc',
    },
  },
});
