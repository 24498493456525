import ListFeedButton from 'pages/Home/components/Map/ListFeedButton';
import LocationInput from 'components/LocationInput';
import RefreshButton from 'components/RefreshButton';
import React, { useEffect, useState } from 'react';
import { Container as MapDiv, Marker, NaverMap } from 'react-naver-maps';
import { Outlet, useNavigate, useOutlet } from 'react-router-dom';
import { FetchPopupStores } from '../../../../services/popup-stores/fetchPopupStores';
import { PopupStore } from '../../../../services/presentation/PopupStore';
import ReactGA from 'react-ga';
import './map.css';

type LatitudeLongitude = {
  latitude: number;
  longitude: number;
};

const Map = () => {
  const outlet = useOutlet();
  return outlet ? <Outlet /> : <MapPage />;
};

const useMapPage = () => {
  const navigate = useNavigate();
  const [popupStores, setPopupStores] = useState<PopupStore[]>([]);
  const [currentLatitudeLongitude, setCurrentLatitudeLongitude] = useState<LatitudeLongitude>({
    latitude: 37.5634092,
    longitude: 127.0369449,
  });

  async function fetchPopupStores() {
    const popupStores = await FetchPopupStores();
    setPopupStores(popupStores);
  }

  useEffect(() => {
    fetchPopupStores();
  }, []);

  const onCenterChanged = (value: naver.maps.Coord) => {
    setCurrentLatitudeLongitude({
      latitude: value.y,
      longitude: value.x,
    });
  };

  const onClickRefresh = async () => await fetchPopupStores()

  const onClickMarker = (id: number) => navigate(`/popup-stores/${id}`);

  const onClickSearch = () => {
    ReactGA.event({
      category: 'Click',
      action: 'search',
      label: 'search'
    });
    navigate('/search')
  };

  const onClickPopupStoreList = () => {

    ReactGA.event({
      category: 'Click',
      action: 'view_list_item',
      label: 'view_list_item'
    });
    navigate('/popup-stores')
  };

  return {
    models: {
      popupStores,
      currentLatitudeLongitude,
    },
    operations: {
      onCenterChanged,
      onClickRefresh,
      onClickSearch,
      onClickPopupStoreList,
      onClickMarker,
    },
  };
}

const MapPage = () => {
  const { models, operations } = useMapPage();

  return (
    <MapDiv
      style={{
        width: '100%',
        height: '100svh',
        zIndex: 9,
      }}
    >
      <LocationInput location="궁금한 팝업스토어를 검색해보세요"
                     onClick={operations.onClickSearch} />
      <ListFeedButton onClick={operations.onClickPopupStoreList} />
      <RefreshButton onClick={operations.onClickRefresh} />

      <NaverMap tileTransition={true}
                disableKineticPan={true}
                defaultZoom={12}
                draggable={true}
                minZoom={7}
                center={{
                  lat: models.currentLatitudeLongitude.latitude,
                  lng: models.currentLatitudeLongitude.longitude
                }}
                onCenterChanged={(value: naver.maps.Coord) => operations.onCenterChanged(value)}>
        {models.popupStores.map((item: PopupStore) => (
          <Marker key={item.id}
                  position={{
                    lat: item.latitude,
                    lng: item.longitude,
                  }}
                  onClick={() => operations.onClickMarker(item.id)}
                  icon={{
                    content: `
                      <div class="bubble">
                        <div class="bubble-img">
                            <img loading="lazy"
                                 src="${item.logoImage}"
                                 alt='logo'
                                 style='width: 18px;
                                 height: 18px;
                                 border-radius: 70%;
                                 z-index: 3;' />
                        </div>
                        
                        <span class="bubble-text" style="margin-left: 5px">${item?.name}</span>
                      </div>
                    `,
                  }} />
        ))}
      </NaverMap>
    </MapDiv>
  );
};

export default Map;