import Home from 'pages/Home';
import ReactGA from "react-ga";

ReactGA.initialize("G-21MDXSC9GV", { debug: true });

const App = () => {
  return (
    <div className="App">
      <Home />
    </div>
  );
};

export default App;
