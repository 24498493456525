import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useAppSelector } from 'app/hooks';
import botRadient from 'assets/icons/bot_radius.png';
import topRadient from 'assets/icons/top_radius.png';
import DialogDownload from 'components/Dialog/DialogDownload';
import BackButton from 'components/IconSVG/BackButton';
import CommentIcon from 'components/IconSVG/CommentIcon';
import FavoriteIcon from 'components/IconSVG/FavoriteIcon';
import LikeIcon from 'components/IconSVG/LikeIcon';
import LocationFeedIcon from 'components/IconSVG/Location';
import ShareIcon from 'components/IconSVG/ShareIcon';
import ViewIcon from 'components/IconSVG/ViewIcon';
import { setLoading } from 'components/Loading/loadingSlice';
import { useEffect, useId, useState } from 'react';
import Carousel, { DotProps } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReactShowMoreText from 'react-show-more-text';
import PlaceService from 'services/place.service';
import { postDetailInitialState } from 'types/entities/post';
import { PostDetailState } from 'types/post.interface';
import { calculateTimeAgo } from 'utils/formartTime';
import { openDeepLink } from 'utils/openDeepLink';
import './style.scss';
import DrawerRoot from '../../../components/Drawer/Drawer';

const PostDetail = () => {
  const navigate = useNavigate();
  const [modalDownload, setModalDownload] = useState<boolean>(false);
  const [bottomSheet, setBottomSheet] = useState<boolean>(true);
  const loading = useAppSelector((store) => store.loading);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    handleGetDetail();
  }, []);

  const [data, setData] = useState<PostDetailState>(postDetailInitialState);
  const handleGetDetail = async () => {
    dispatch(setLoading(true));
    const res: any = await PlaceService.getPostDetailId(id);

    setData(res);

    dispatch(setLoading(false));
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const CustomDots = ({ index, active, onClick, carouselState }: DotProps) => {
    return (
      <Box
        sx={{
          borderRadiusEnd: '10px',
          marginRight: '4px',
          height: '3px',

          backgroundColor: active ? '#838383' : '#343434',
          zIndex: 9,
          width: '100%',
          color: 'white'
        }}
        onClick={() => onClick}
      ></Box>
    );
  };
  const idx = useId();
  return loading.loading ? (
    <></>
  ) : (

    <div id="drawer-container"
         style={{
           position: 'relative',
         }}>
      <Box className="feed-detail">
        <Box>
          <img className="top-radient" src={topRadient} />
        </Box>
        <Carousel
          customDot={<CustomDots />}
          renderDotsOutside={true}
          dotListClass=""
          showDots={data.data.post.images.length > 1 ?? true}
          arrows={false}
          className="react-multi-carousel-list"
          responsive={responsive}
        >
          {data.data.post.images.map((img) => (
            <Box key={idx}>
              <img className="img-bg" src={img.url} alt="" />
            </Box>
          ))}
        </Carousel>
        <Box className="top-location">
          <Box className="back-button">
            <IconButton onClick={() => navigate(-1)}>
              <BackButton />
            </IconButton>
          </Box>
          <Box className="text-top-location">
            <Box className="ico">
              <LocationFeedIcon />
            </Box>
            <Box className="text">{data.data.post.location.name}</Box>
          </Box>
        </Box>
        <Box className="media-social">
          <IconButton className="social-view">
            <ViewIcon />
          </IconButton>
          <Box className="social-count">{data?.data?.post?.totalViews ?? 0}</Box>
          <IconButton
            className="social-icon"
            onClick={() => setBottomSheet(true)}
          >
            <LikeIcon />
          </IconButton>
          <Box className="social-count">{data?.data?.post?.totalLikes ?? 0}</Box>
          <IconButton
            className="social-icon"
            onClick={() => setBottomSheet(true)}
          >
            <CommentIcon />
          </IconButton>
          <Box className="social-count">
            {data?.data?.post?.totalComments ?? 0}
          </Box>
          <IconButton
            className="social-icon"
            onClick={() => setBottomSheet(true)}
          >
            <FavoriteIcon />
          </IconButton>
          <Box className="social-count" onClick={() => setBottomSheet(true)}>
            {data?.data?.post?.totalBookMarks ?? 0}
          </Box>
          <IconButton
            className="social-share"
            onClick={() => setBottomSheet(true)}
          >
            <ShareIcon />
          </IconButton>
        </Box>
        <Box>
          <Box className="feed-content-author">
            <Box className="infomation">
              <Avatar
                className="infomation-avt"
                src={data?.data?.post?.user.avatar}
              />
              <Box className="infomation-detail">
                <Box className="infomation-detail-name">
                  <Box className="infomation-detail-name-author ">
                    {data?.data?.post?.user.nickname}
                  </Box>
                  <Box className="infomation-detail-name-id ">{`@${data?.data?.post?.user.alias}`}</Box>
                </Box>
                <Box className="infomation-detail-upload-time ">
                  {calculateTimeAgo(data?.data?.post?.createdAtImage, true)}
                </Box>
              </Box>
            </Box>
            <Box className="infomation-location">
              <Box className="location-detail">
                <Box className="icon">
                  <LocationFeedIcon />
                </Box>
                <Box className="address"> {data?.data?.post?.location.name}</Box>
              </Box>
              <Box className="location-descripstion">
                <ReactShowMoreText
                  /* Default options */
                  lines={1}
                  more="더보기"
                  less="숨기기"
                  className="content-css"
                  anchorClass="show-more-less-clickable"
                  expanded={false}
                  width={480}
                  truncatedEndingComponent={'...'}
                >
                  {data?.data?.post?.content ? data?.data?.post?.content : ''}
                </ReactShowMoreText>
              </Box>
            </Box>
            <DialogDownload
              handleClose={() => setModalDownload(false)}
              handleOk={openDeepLink}
              open={modalDownload}
            />
          </Box>
          <img className="bottom-radient" src={botRadient} />
        </Box>
      </Box>

      <DrawerRoot open={bottomSheet} hideDrawer={() => setBottomSheet(false)} />
    </div>
  );
};

export default PostDetail;
