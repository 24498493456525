import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import BackButton from '../../components/BackButton/BackButton';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import AppleLogo from '../Home/AppleLogo';
import ChevronRightWhite from 'assets/icons/chevronright_white.svg';
import BackgroundImage from 'assets/images/introduce_background.png';
import Typography from '@mui/material/Typography';

import SugarImage from "assets/images/sugar_money_icon.png";
import PopupImage from "assets/images/popup_img.png";
import NaverImage from "assets/images/naverpay_img.png";

export default function Introduces() {
  const navigate = useNavigate();

  return (
    <Box
      zIndex={100}
      sx={{
        height: '100vh',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
      }}>
      <Grid container
            justifyContent="space-between"
            sx={{
              position: 'sticky',
              top: '0',
              padding: '20px 10px 10px 10px',
            }}>
        <Grid item>
          <Grid container
                justifyContent="center"
                alignItems="center"
                height="100%">
            <BackButton onClick={() => navigate(-1)}
                        isWhite />
          </Grid>
        </Grid>
      </Grid>

      <Box display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            height="80%"
            zIndex={100}>
        <Box display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              zIndex={100}
              sx={{
                width: '100%',
              }}>
          <Typography fontFamily="KBODiaGothic"
                      fontSize="22px"
                      fontWeight={500}
                      color="white"
                      textAlign="center"
          >지금 프레젬 회원가입하고,</Typography>
          <Box display="flex"
               justifyContent="center"
               alignItems="center"
               flexDirection="row"
               zIndex={100}
               sx={{
                 width: '100%',
               }}>
            <Typography fontFamily="KBODiaGothic"
                        fontSize="22px"
                        fontWeight={700}
                        color="white"
                        textAlign="center"
                        lineHeight="28px"
            >리워드 설탕</Typography>
            <Typography fontFamily="KBODiaGothic"
                        fontSize="22px"
                        fontWeight={500}
                        color="white"
                        textAlign="center"
                        lineHeight="28px"
            >&nbsp;받아가세요!</Typography>
          </Box>
        </Box>

        <Box display="flex"
             justifyContent="center"
             alignItems="center"
             my={1.5}
             zIndex={100}>
          <Box display="flex"
               flexDirection="row"
               justifyContent="center"
               alignItems="center"
               zIndex={100}
               sx={{
                 height: '32px',
                 borderRadius: '16px',
                 border: '1px solid #FF9B82',
                 background: 'linear-gradient(128.05deg, #BF1D0C -30.42%, #590D06 160.94%)',
                 padding: '15px 20px',
               }}>
            <Typography fontFamily="KBODiaGothic"
                        fontSize="16px"
                        fontWeight={500}
                        color="white"
                        textAlign="center"
                        lineHeight="16px"
            >신규가입 혜택</Typography>
            <Typography fontFamily="KBODiaGothic"
                        fontSize="16px"
                        fontWeight={700}
                        color="white"
                        textAlign="center"
                        lineHeight="16px"
            >&nbsp;EVENT</Typography>
          </Box>
        </Box>

        <Grid container>
          <Grid item
                xs={12}
                pl="24px"
                pr="24px"
                zIndex={100}>
            <Box display="flex"
                 justifyContent="space-between"
                 alignItems="center"
                 sx={{
                   padding: '10px 20px',
                   width: '100%',
                   background: 'linear-gradient(90deg, #FFEBE9 0%, #FFF7F4 100%)',
                   borderRadius: '8px',
                 }}>
              <Box>
                <Typography fontFamily="KBODiaGothic"
                            fontSize="14px"
                            fontWeight={500}
                            color="#979797"
                            lineHeight="14px">신규가입 보상</Typography>
                <Typography fontFamily="KBODiaGothic"
                            fontSize="16px"
                            fontWeight={700}
                            color="#FF6151"
                            lineHeight="16px"
                            sx={{
                              marginTop: '10px',
                            }}>회원가입 100설탕 지급</Typography>
              </Box>
              <Box component="img"
                   src={SugarImage}
                   width="73px"
                   height="60px"
              />
            </Box>
          </Grid>

          <Grid item
                xs={12}
                pl="24px"
                pr="24px"
                mt={1}
                zIndex={100}>
            <Box display="flex"
                 justifyContent="space-between"
                 alignItems="center"
                 sx={{
                   padding: '10px 20px',
                   width: '100%',
                   background: 'linear-gradient(90deg, #FFEBE9 0%, #FFF7F4 100%)',
                   borderRadius: '8px',
                 }}>
              <Box>
                <Typography fontFamily="KBODiaGothic"
                            fontSize="14px"
                            fontWeight={500}
                            color="#979797"
                            lineHeight="14px">팝업후기 보상</Typography>
                <Typography fontFamily="KBODiaGothic"
                            fontSize="16px"
                            fontWeight={700}
                            color="#FF6151"
                            lineHeight="16px"
                            sx={{
                              marginTop: '10px',
                            }}>팝업후기 리워드 설탕 지급</Typography>
              </Box>
              <Box component="img"
                   src={PopupImage} />
            </Box>
          </Grid>

          <Grid item
                xs={12}
                pl="24px"
                pr="24px"
                mt={1}
                zIndex={100}>
            <Box display="flex"
                 justifyContent="space-between"
                 alignItems="center"
                 sx={{
                   padding: '10px 20px',
                   width: '100%',
                   background: 'linear-gradient(90deg, #FFEBE9 0%, #FFF7F4 100%)',
                   borderRadius: '8px',
                 }}>
              <Box>
                <Typography fontFamily="KBODiaGothic"
                            fontSize="14px"
                            fontWeight={500}
                            color="#979797"
                            lineHeight="14px">설탕을 현금처럼</Typography>
                <Typography fontFamily="KBODiaGothic"
                            fontSize="16px"
                            fontWeight={700}
                            color="#FF6151"
                            lineHeight="16px"
                            sx={{
                              marginTop: '10px',
                            }}>설탕 네이버페이 출금 가능</Typography>
              </Box>
              <Box component="img"
                   src={NaverImage} />
            </Box>
          </Grid>

          <Grid item
                xs={12}
                pl="24px"
                pr="24px"
                mt={1}
                zIndex={100}>
            <Button className="button"
                    target="_blank"
                    href="https://apps.apple.com/kr/app/prezem-%ED%94%84%EB%A0%88%EC%A0%AC/id6446088313"
                    startIcon={<AppleLogo fill={"white"}/>}
                    endIcon={<Box component="img" src={ChevronRightWhite} />}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '15px',
                      borderRadius: '8px',
                      padding: '5px 16px',
                      backgroundColor: '#000000',
                      color: 'white',
                      fontFamily: 'Apple SD Gothic Neo',

                      "&:hover": {
                        backgroundColor: '#000000',
                      }
                    }}
            >
              1분 내로 가입하고 설탕받기
            </Button>
          </Grid>
        </Grid>

      </Box>
    </Box>
  );
}
